import { ACCESS_TOKEN_KEY } from '../settings/constants';
import { Decrypt, Encrypt } from './cryption';

// ACCESS TOKEN

export const setAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, Encrypt(accessToken, ACCESS_TOKEN_KEY));
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (!accessToken) return null;
  return Decrypt(accessToken, ACCESS_TOKEN_KEY);
};

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

// AUTH

export const isLoggedIn = () => localStorage.getItem(ACCESS_TOKEN_KEY) != null;

export const logout = () => {
  removeAccessToken();
  window.location.reload();
};
