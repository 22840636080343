import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import { LOGIN_ENDPOINT } from '../../settings/endpoint';
import { getAccessToken, setAccessToken } from '../../utils/auth';
import makeRequest from '../../utils/fetcher';
import { isValidToken } from './utils';

const initialState = {
  user: null,
  loading: true,
  accessToken: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  if (type === 'INITIAL') {
    return {
      loading: false,
      accessToken: payload.accessToken,
    };
  }
  if (type === 'LOGIN') {
    return {
      ...state,
      accessToken: payload.accessToken,
    };
  }
  if (type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      const accessToken = getAccessToken();

      if (accessToken && isValidToken(accessToken)) {
        dispatch({
          type: 'INITIAL',
          payload: {
            accessToken,
          },
        });
      } else
        dispatch({
          type: 'INITIAL',
          payload: {
            accessToken: null,
          },
        });
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          accessToken: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback((params, successCallback, errorCallback) => {
    makeRequest().post(
      LOGIN_ENDPOINT,
      params,
      (result) => {
        setAccessToken(result.accessToken);
        dispatch({
          type: 'LOGIN',
          payload: {
            accessToken: result.accessToken,
          },
        });
        if (successCallback) successCallback(result);
      },
      (error) => {
        if (errorCallback) errorCallback(error);
      }
    );
  }, []);

  const checkAuthenticated = state.accessToken ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
    }),
    [login, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
