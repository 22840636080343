import {
  Box,
  Card,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocales } from 'src/locales';
import Iconify from '../../../components/iconify/iconify';
import { LoadingScreen } from '../../../components/loading-screen';
import { PAGE_SIZES } from '../../../config-global';
import makeRequest from '../../../utils/fetcher';
import { fAmount } from '../../../utils/format';
import { fDateTime } from '../../../utils/format-time';

const TYPES = {
  'volume-boosters': 'VOLUME',
  'market-makers': 'MAKER',
};

function OrderHistoryTable() {
  const { type } = useParams();
  const { t } = useLocales();
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);

  const [result, setResult] = useState(null);

  const [errMsg, setErrMsg] = useState('');

  const [isFetching, setIsFetching] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPage(0);
    setPageSize(parseInt(event.target.value, 10));
  };

  const getOrderHistory = useCallback(
    (loading = true) => {
      const params = {
        page: page + 1,
        pageSize,
        search: '',
        orderBy: '',
        isDesc: true,
        returnStatistic: false,
        filters: {
          BotType: TYPES[type],
        },
      };

      if (loading) {
        setIsFetching(true);
        setErrMsg('');
      }

      makeRequest().post(
        '/bot/order-history',
        params,
        (res) => {
          // const newArr = [];

          // res.items.forEach((item) => {
          //   if (item.botType === TYPES[type]) newArr.push(item);
          // });

          // res.items = newArr;

          setResult(res);
          setIsFetching(false);
        },
        (err) => {
          setErrMsg(err.msg || err.code);
          setIsFetching(false);
        }
      );
    },
    [page, pageSize, type]
  );

  useEffect(() => {
    getOrderHistory();
  }, [getOrderHistory]);

  useEffect(() => {
    const interval = setInterval(() => {
      getOrderHistory(false);
    }, 5000);

    return () => clearInterval(interval);
  }, [getOrderHistory]);

  const isFirstFetching = result === null && isFetching;
  const isLoading = isFetching && !isFirstFetching;
  const isNotFound = result !== null && result.items.length === 0;

  const config = [
    {
      key: 'botId',
      label: 'BOT ID',
      render: (data) => (
        <Typography variant="subtitle1" whiteSpace="nowrap">
          #{data.botId}
        </Typography>
      ),
    },
    {
      key: 'side',
      label: t('lang_033'),
      render: (data) => (
        <Typography variant="body1" whiteSpace="nowrap">
          {data.side}
        </Typography>
      ),
    },
    {
      key: 'symbol',
      label: t('lang_011'),
      render: (data) => (
        <Typography variant="body1" whiteSpace="nowrap">
          {data.symbol}
        </Typography>
      ),
    },
    {
      key: 'price',
      label: t('lang_058'),
      render: (data) => (
        <Typography variant="body1" whiteSpace="nowrap">
          {fAmount(data.price)}
        </Typography>
      ),
    },
    {
      key: 'origQty',
      label: t('lang_059'),
      render: (data) => (
        <Typography variant="body1" whiteSpace="nowrap">
          {data.origQty}
        </Typography>
      ),
    },
    {
      key: 'transactTime',
      label: t('lang_060'),
      render: (data) => (
        <Typography variant="subtitle2" whiteSpace="nowrap">
          {fDateTime(data.transactTime)}
        </Typography>
      ),
    },
  ];

  return (
    <Stack component={Card}>
      <Box p={2}>
        <Typography variant="h5">{t('lang_061')}</Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {config.map((head) => (
                <TableCell key={head.key} align={head.align || 'left'}>
                  {head.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {result &&
              result.items.map((row, index) => (
                <TableRow key={index} hover>
                  {config.map((col) => {
                    if (isLoading)
                      return (
                        <TableCell key={col.key} align="left">
                          <Skeleton variant="rounded" width="50%" />
                        </TableCell>
                      );
                    return (
                      <TableCell key={col.key} align={col.align || 'left'}>
                        {col.render ? col.render(row) : row[col.key]}
                      </TableCell>
                    );
                  })}
                  <TableCell />
                </TableRow>
              ))}

            {isFirstFetching && (
              <TableRow>
                <TableCell colSpan={config.length + 1} sx={{ height: 360 }}>
                  <LoadingScreen />
                </TableCell>
              </TableRow>
            )}

            {Boolean(errMsg) && (
              <TableRow>
                <TableCell
                  colSpan={config.length + 1}
                  sx={{ height: 360, background: theme.palette.error.main }}
                  align="center"
                >
                  <Iconify
                    icon="nonicons:error-16"
                    sx={{ height: 120, width: 120, opacity: 0.5 }}
                  />
                  <Typography variant="h4" sx={{ color: theme.palette.error.contrastText }}>
                    {errMsg}
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {isNotFound && (
              <TableRow>
                <TableCell colSpan={config.length + 1} sx={{ height: 360 }} align="center">
                  <Iconify
                    icon="iconoir:file-not-found"
                    sx={{ height: 120, width: 120, opacity: 0.5 }}
                  />
                  <Typography variant="h4" sx={{ color: theme.palette.error.contrastText }}>
                    {t('lang_004')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={PAGE_SIZES}
        component="div"
        count={result?.itemCount || 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangePageSize}
      />
    </Stack>
  );
}

export default OrderHistoryTable;
