export function fAmount(number, fix) {
  try {
    let str = `${number}`;
    if (str.indexOf('e') >= 0) {
      str = `${number.toFixed(8)}`;
    }
    const deleteText = str.replace(/[^\d.]/g, '');
    const x = deleteText.split('.');
    let x1 = x[0];
    const x2 = x[1];
    const x3 = x.length > 1 ? `.${x2.slice(0, fix || 8)}` : '';
    if (!x1) x1 = '0';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1,$2');
    }
    const rs = (x1 + x3).replace(/^0+(?!\.|$)/, '').replace(/^\./, '');
    return `${number < 0 ? '-' : ''}${rs}`;
  } catch (e) {
    return '0.00';
  }
}
