// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    bot: {
      root: `${ROOTS.DASHBOARD}/bot`,
      volume: `${ROOTS.DASHBOARD}/bot/volume-boosters`,
      maker: `${ROOTS.DASHBOARD}/bot/market-makers`,
    },
  },
};
