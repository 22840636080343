import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';
import GuestGuard from 'src/contexts/auth/guard/GuestGuard';
import AuthClassicLayout from 'src/layouts/auth/classic';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('../../pages/auth/LoginPage'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthClassicLayout>
            <LoginPage />
          </AuthClassicLayout>
        ),
      },
    ],
  },
];
