import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import BotMakerView from '../../sections/dashboard/bot/bot-maker/BotMakerView';
import BotVolumeView from '../../sections/dashboard/bot/bot-volume/BotVolumeView';
import NotFoundPage from '../404';

function DashboardBotPage() {
  const { type } = useParams();

  const renderView = () => {
    if (type === 'volume-boosters') {
      return <BotVolumeView />;
    }
    if (type === 'market-makers') {
      return <BotMakerView />;
    }
    return <NotFoundPage />;
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: BOT</title>
      </Helmet>

      {renderView()}
    </>
  );
}

export default DashboardBotPage;
