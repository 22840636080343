import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children }) {
  return (
    <Stack position="relative" height="100vh" alignItems="center" justifyContent="center">
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          top: 0,
          left: 0,
          m: { xs: 2, md: 5 },
        }}
      />

      <Stack>{children}</Stack>
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
};
