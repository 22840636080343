// const production = {};

// const staging = {};

// const isLocal = Boolean(
//   window.location.hostname === 'localhost' ||
//     window.location.hostname === '[::1]' ||
//     window.location.hostname.match(/^129(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
// );

// const isDev = window.location.hostname.indexOf('binggo.1fit.fun') > -1;

// export const isNotProduction = isLocal || isDev;

export const API = 'https://srspace.co/api';
